// Variables
@import "variables";

#footer {
  .footer-contents {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $verde;
    color: blue;
    padding: 0 15px;
    .footer {
      color: white;
      margin: 5px 15px;
    }
  }
}
