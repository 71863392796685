// Variables
@import "variables";

#navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .navbar .navbar-nav > li > a {
    color: white;
    font-size: 18px;
    padding: 17px;
    &:hover {
      background-color: $verdescuro;
    }
  }
  .navbar .navbar-nav > .active > a {
    color: white;
    background-color: #2a88bd;
  }
  .navbar {
    background-color: $verde;
    border: none;
    border-radius: 0;
    margin-bottom: 50px;
    padding: 0;
    min-height: 60px;
    .navbar-brand {
      padding: 1px 15px;
      .logo {
        height: 44px;
      }
    }
  }

  .navbar .navbar-toggle {
    border-color: $verdescuro;
    background-color: $verdescuro;
    .icon-bar {
      background-color: white;
    }
  }

  .navbar .navbar-nav > .open > a,
  .navbar .navbar-nav > .open > a:hover,
  .navbar .navbar-nav > .open > a:focus {
    background-color: $verdescuro;
  }
  .dropdown-menu {
    color: white;
    background-color: $verde;
    width: 100%;
    margin-top: 14px;
    & > li > a {
      display: block;
      color: white;
      font-size: 16px;
      padding: 3px 20px;

      &:hover {
        background-color: $verdescuro;
      }
    }
  }
}
