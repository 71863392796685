// Variables
@import "variables";

html, body {
  font-family: Raleway, sans-serif;
  background-image: url('/images/BG.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #636b6f;
}

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

button, .bottone {
  color: white;
  text-align: center;
  padding: 5px 20px;
  border: 0;
  border-radius: 7px;
  background: $brand-primary;
  transition: all .3s;
  outline: none;
  &:hover {
    color: white;
    background-color: $verde;
    transition: all .3s;
  }
}

select {
  display: inherit;
  width: 100%;
  height: 40px;
}

a {
  outline: none;
  text-decoration: none;
  color: $brand-primary;

  &:hover {
    text-decoration: none;
    color: $brand-success;
  }
  &:visited, &:active, &:focus {
    text-decoration: none;
    color: white;
  }
}

.card {
  margin: 20px;
}

.container {
  background-color: white;
  font-size: 20px;
  box-shadow: 6px 6px 7px #9d9d9d;
}
.riga {
  padding: 10px 0;
  &.grigia {
    background-color: lightgrey;
  }
  &.totali {
    background-color: lightblue;
  }
}

.title {
  font-size: 40px;
  font-weight: bold;
  @media #{$viewport-xs} {
    font-size: 30px;
  }
}

.no-margin {
  margin: 0;
}

.alert {
  font-size: 30px;
}

#page-container{
  margin-top: 75px;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.form-control {
  font-size: 18px;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-color: #b1b7ba;
  &:focus {
    box-shadow: none;
    border-color: $brand-primary;
  }
  .current {
    margin-top: -7px;
  }
}

.carousel {
  margin-top: 50px;
}

.nice-select {
  border-bottom: 1px solid #b1b7ba;
  height: 37px;
  &::after {
    border-bottom: 2px solid #b1b7ba;
    border-right: 2px solid #b1b7ba;
  }
  .current {
    float: left;
  }
}

.user {
  margin: 60px 0;
  .bottone {
    min-width: 150px;
    display: inline-block;
    margin: 30px 10px 0;
  }
  a {
    color: white;
  }
}

.spazio {
  padding: 12px 0;
}

.group {
  border: 1px solid #b1b7ba;
  border-radius: 10px;
  margin: 50px 0;
  position: relative;
  .group-title {
    position: absolute;
    top: -23px;
    left: 15px;
    background: white;
    padding: 0 10px;
  }
}

.xtra {
  @media #{$viewport-sm} {
    padding: 0 35px;
  }
  @media #{$viewport-md-lg} {
    padding-left: 35px;
  }
}

.bottom-space {
  @media #{$viewport-xs} {
    margin-bottom: 10px;
  }
}

.row-flex {
  display: flex;
  justify-content: end;
  align-items: center;
  min-height: 45px;
  @media #{$viewport-sm-md} {
    min-height: 65px;
  }
  @media #{$viewport-xs} {
    display: block;
    text-align: right;
    margin-top: 20px;
    margin-bottom: -10px;
  }
}

.panel {
  margin-top: 20px;
}

.form-horizontal {
  .btn-link {
    color: #216a94;
  }
}

.res {
  display: inline-block;
  margin: 20px;
  max-height: 100px;
}

.lightblue{
  background-color: lightblue;
  padding: 10px;
}
