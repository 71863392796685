// Variables
@import "variables";

#invoice {
  @media #{$viewport-xs} {
    font-size: 10px;
  }
  td {
    padding: 0 15px;
    @media #{$viewport-xs} {
      padding: 0 5px;
    }
  }
  tbody {
    border: 1px solid black;
  }
  padding: 40px 70px;
  @media #{$viewport-xs} {
    padding: 0;
  }
  .spacer {
    height: 60px;
    @media #{$viewport-xs} {
      height: 15px;
    }
  }
  .intestazione {
    margin-top: 20px;
  }
  .destinatario {
    line-height: 1.2;
  }
  .operazioni {
    line-height: 1.1;
    font-size: 12px;
    font-style: italic;
    @media #{$viewport-xs} {
      font-size: 9px;
      margin-bottom: 15px;
    }
  }
}