// Variables
@import "variables";

#invoices {
  @media #{$viewport-xs} {
    line-height: 1.6;
  }
  .riga {
    line-height: 1.1;
  }
  .bottone-add {
    font-size: 14px;
    text-align: center;
    padding: 5px 20px;
    border: 0;
    border-radius: 7px;
    background: $brand-primary;
    transition: all .3s;
    &:hover {
      color: white;
      background-color: $verde;
      transition: all .3s;
    }
  }
}