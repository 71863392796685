// Variables
@import "variables";

#bar {
  margin: 30px 10px 50px;
  @media #{$viewport-xs} {
    margin: 20px 0 50px;
  }
  .bottone {
    margin: 0 12px;
    box-shadow: 3px 3px 5px #9d9d9d;
    @media #{$viewport-xs} {
      padding: 5px 15px;
    }
  }
}