// Variables
@import "variables";

.modal-content {
  .modal-header {
    display: block;
    margin-left: 5px;
    border-bottom: none;
  }
  .close {
    font-size: 50px;
    margin-right: 20px;
    &:hover {
      background-color: transparent;
    }
  }
  .modal-footer {
    border-top: none;
  }
}