// Variables
@import "variables";

#client {
  td {
    padding: 0 15px;
  }
  tbody {
    border: 1px solid black;
  }
}